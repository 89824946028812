
import { defineComponent, ref, reactive } from 'vue'
import { chrome } from '@/guides/endpoints/chrome'
import { create } from '@/guides/endpoints/projects'

import Alert from '@/components/Alert.vue'
import BackBtn from '@/components/BackBtn.vue'
import FormInput from '@/components/FormInput.vue'
import Btn from '@/components/Btn.vue'
import Feedback from '@/components/Feedback.vue'
import SelectCustom from '@/components/SelectCustom.vue'
import Upload from '@/components/Upload.vue'

export default defineComponent({
  components: {
    Alert,
    FormInput,
    Btn,
    BackBtn,
    Feedback,
    SelectCustom,
    Upload,
  },
  setup() {
    const chromeData = ref()
    const showAlert = ref(false)
    const uploadPhotos = ref()
    const typesList = ref()

    const projectTypes = ref<Array<string>>([''])
    const addTypes = () => {
      projectTypes.value.length += 1
    }
    const removeTypes = (index: any) => {
      const type = document.querySelector(`#scw-${index}`)

      type?.remove()
    }

    const tempHousing = [
      { name: 'Tentes', slug: 'Tentes' },
      { name: 'Bâtiments', slug: 'Bâtiments' },
      { name: 'Autres', slug: 'Autres' },
    ]

    chrome().then(r => {
      chromeData.value = r.data

      typesList.value = chromeData.value.lists.projectTypes.filter(function(
        type: any
      ) {
        return !type.slug.includes('projet-annee-')
      })
    })

    // Category
    const category = ref('projet-camp') //projet-camp vs projet-dannee

    const updateCategory = (value: any) => {
      category.value = value

      typesList.value = chromeData.value.lists.projectTypes.filter(function(
        type: any
      ) {
        if (value === 'projet-dannee') {
          return type.slug.includes('projet-annee-')
        } else {
          return !type.slug.includes('projet-annee-')
        }
      })
    }

    const formel = ref()
    const showError = ref({})
    const badgeFeedback = ref('loading')

    const form = reactive({})

    const feedbackVisible = ref(false)
    const loading = ref(false)

    const showFeedback = () => {
      feedbackVisible.value = true
    }

    const onError = (error: any) => {
      feedbackVisible.value = false
      showError.value = error

      showAlert.value = true

      window.setTimeout(() => {
        showAlert.value = false
      }, 5000)
    }

    const onSuccess = () => {
      badgeFeedback.value = 'success'
    }

    const onSubmit = () => {
      if (loading.value) {
        return
      }

      showError.value = {}
      loading.value = true
      feedbackVisible.value = true

      const data = new FormData(formel.value)

      data.append('category', category.value as string)

      const photos = uploadPhotos.value.getFiles()
      data.delete('photos[]')

      if (photos.length) {
        photos.forEach((el: any) => {
          data.append('photos[]', el.file)
        })
      }

      create(data)
        .then(d => {
          if (d.data?.status === 200) {
            onSuccess()
          } else {
            onError(d.data)
          }
        })
        .catch(() => {
          onError({
            message: !navigator.onLine
              ? 'Il semblerait que tu sois hors connexion. Connecte toi à internet pour envoyer ton formulaire.'
              : "Il semblerait qu'une erreur ce soit glissée quelque part.",
          })
        })
        .finally(() => {
          loading.value = false
        })
    }

    return {
      form,
      feedbackVisible,
      showFeedback,
      onSubmit,
      loading,
      formel,
      showError,
      badgeFeedback,
      showAlert,
      uploadPhotos,
      chromeData,
      projectTypes,
      addTypes,
      removeTypes,
      updateCategory,
      category,
      tempHousing,
      typesList,
    }
  },
})
