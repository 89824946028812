import api from '@/guides/api'

export const list = () => {
  return api.get(`/pages/projects`)
}

export const single = (slug: string | string[]) => {
  return api.get(`/projects/${slug}`)
}

export const create = (data: any) => {
  return api.post('/projects', data)
}
