<template>
  <div class="project-form fill-height" v-if="chromeData">
    <Alert :show="showAlert && !!showError?.message">
      <SvgSprite symbol="icons-alert" size="0 0 14 11" />
      <span>Erreur : {{ showError?.message }}</span>
    </Alert>
    <BackBtn />
    <div class="wrapper wrapper--small p-l">
      <h3 class="mb-m">Ajouter un projet</h3>

      <form ref="formel" @submit.prevent="onSubmit" method="POST">
        <!-- CATEGORY -->
        <div class="category mb-m">
          <h4 class="mb-xs">Catégorie du projet</h4>

          <div class="category-selector mb-s">
            <div
              @click="updateCategory('projet-camp')"
              class="category-selector__item"
              :class="{ 'is-active': category === 'projet-camp' }"
            >
              <span class="h5">Projet camp</span>
            </div>
            <div
              @click="updateCategory('projet-dannee')"
              class="category-selector__item"
              :class="{ 'is-active': category === 'projet-dannee' }"
            >
              <span class="h5">Projet d'année</span>
            </div>
          </div>
        </div>

        <!-- ABOUT -->
        <div class="about mb-m">
          <h4 class="mb-xs">À propos</h4>

          <div class="mb-s">
            <h5>Titre du projet *</h5>
            <p class="description mb-xxs">
              Descriptif de l'activité en 6-7 mots
            </p>
            <FormInput name="title" :required="true" />
          </div>

          <div class="mb-s">
            <h5 class="mb-xxs">Type de projet *</h5>

            <div
              class="select-custom-wrapper"
              :id="`scw-${index}`"
              v-for="(item, index) in projectTypes"
              :key="`type-${index}`"
            >
              <SelectCustom
                class="mb-xxs"
                name="types[]"
                placeholder="Type de projet"
                :data="typesList"
              />

              <a class="remove" @click.prevent="removeTypes(index)">
                <SvgSprite symbol="ui-close" size="16" />
              </a>
            </div>

            <a
              class="add"
              @click.prevent="addTypes"
              v-if="projectTypes.length < 50"
            >
              <SvgSprite symbol="icons-add" size="16" />
              <span>Ajouter un type de projet</span>
            </a>
          </div>

          <div class="mb-s">
            <h5 class="mb-xxs">Lieu du projet *</h5>
            <FormInput name="location" :required="true" />
          </div>

          <label class="mb-s">
            <h5 class="mb-xxs">Résumé du projet *</h5>
            <textarea
              name="summary"
              placeholder="max 450 caractères espace compris"
              maxlength="450"
              required
            ></textarea>
          </label>
        </div>

        <!-- PARTNER -->
        <div class="partner mb-m">
          <h4 class="mb-xs">Partenaire éventuel</h4>

          <div class="mb-s">
            <h5 class="mb-xxs">Nom du partenaire</h5>
            <FormInput name="partnerName" />
          </div>

          <label class="mb-s">
            <h5 class="mb-xxs">Objectif du partenaire</h5>
            <textarea
              name="partnerObjective"
              placeholder="max 450 caractères espace compris"
              maxlength="450"
            ></textarea>
          </label>
        </div>

        <!-- ACTIVITY -->
        <div class="activity mb-m">
          <h4 class="mb-xs">Activités réalisées</h4>

          <div class="mb-s">
            <h5 class="mb-xxs">Activités réalisées *</h5>
            <FormInput name="activities" :required="true" />
          </div>
        </div>

        <!-- AVIS -->
        <div class="feedback mb-m">
          <h4 class="mb-xs">Votre avis</h4>

          <label class="mb-s">
            <h5 class="mb-xxs">Qu'avez-vous pensé du projet? *</h5>
            <textarea
              name="feedback"
              placeholder="max 450 caractères espace compris"
              maxlength="450"
              required
            ></textarea>
          </label>

          <label class="mb-s ">
            <h5 class="mb-xxs">
              Comment étaient les relations avec le partenaire *
            </h5>
            <textarea
              name="relationWithPartner"
              placeholder="max 450 caractères espace compris"
              maxlength="450"
              required
            ></textarea>
          </label>

          <label class="mb-s">
            <h5 class="mb-xxs">Avez-vous des tuyaux? *</h5>
            <textarea
              name="tips"
              placeholder="max 450 caractères espace compris"
              maxlength="450"
              required
            ></textarea>
          </label>
        </div>

        <!-- DETAILS PRATIQUES | IF CAMP -->
        <div class="pratical mb-m" v-if="category === 'projet-camp'">
          <h4 class="mb-xs">Détails pratiques</h4>

          <label class="mb-s">
            <h5 class="mb-xxs">Capacité d'accueil de l'endroit</h5>
            <textarea
              name="capacity"
              placeholder="max 450 caractères espace compris"
              maxlength="450"
            ></textarea>
          </label>

          <label class="mb-s">
            <h5 class="mb-xxs">Durée du projet</h5>
            <textarea
              name="duration"
              placeholder="max 450 caractères espace compris"
              maxlength="450"
            ></textarea>
          </label>

          <div class="mb-s">
            <h5 class="mb-xxs">
              Participation financière demandée par le partenaire
            </h5>
            <FormInput name="participation" />
          </div>

          <div class="mb-s">
            <h5 class="mb-xxs">Type de logement</h5>
            <SelectCustom
              name="housing"
              placeholder="Type de logement"
              :data="tempHousing"
            />
          </div>

          <!-- OPTION TYPE DE LOGEMENT -->
        </div>

        <!-- PARTENAIRES BE -->
        <div class="belgian-partner-infos mb-m">
          <h4 class="mb-xs" v-if="category === 'projet-camp'">
            Partenaire en Belgique
          </h4>
          <h4 class="mb-xs" v-else>Partenaire</h4>

          <div class="mb-s">
            <h5 class="mb-xxs">Nom du partenaire</h5>
            <FormInput name="belgianPartnerName" />
          </div>

          <div class="infos mb-s">
            <div class="mb-s">
              <h5 class="mb-xxs">Nom de la personne de contact</h5>
              <FormInput name="belgianPartnerLastname" />
            </div>

            <div class="mb-s">
              <h5 class="mb-xxs">Prénom de la personne de contact</h5>
              <FormInput name="belgianPartnerFirstname" />
            </div>
          </div>

          <label class="mb-s">
            <h5 class="mb-xxs">Adresse</h5>
            <textarea
              name="belgianPartnerAddress"
              placeholder="max 450 caractères espace compris"
              maxlength="450"
            ></textarea>
          </label>

          <div class="infos mb-s">
            <div class="mb-s">
              <h5 class="mb-xxs">Téléphone</h5>
              <FormInput name="belgianPartnerPhone" />
            </div>

            <div class="mb-s">
              <h5 class="mb-xxs">E-mail</h5>
              <FormInput name="belgianPartnerEmail" />
            </div>
          </div>

          <div class="mb-s">
            <h5 class="mb-xxs">Site internet</h5>
            <FormInput name="belgianPartnerWebsite" />
          </div>
        </div>

        <!-- PARTENAIRES INT | IF ANNEE -->
        <div
          class="onsite-partner-infos mb-m"
          v-if="category === 'projet-camp'"
        >
          <h4 class="mb-xs">Partenaire sur place</h4>

          <div class="mb-s">
            <h5 class="mb-xxs">Nom du partenaire</h5>
            <FormInput name="onsitePartnerName" />
          </div>

          <div class="infos mb-s">
            <div class="mb-s">
              <h5 class="mb-xxs">Nom de la personne de contact</h5>
              <FormInput name="onsitePartnerLastname" />
            </div>

            <div class="mb-s">
              <h5 class="mb-xxs">Prénom de la personne de contact</h5>
              <FormInput name="onsitePartnerFirstname" />
            </div>
          </div>

          <label class="mb-s">
            <h5 class="mb-xxs">Adresse</h5>
            <textarea
              name="onsitePartnerAddress"
              placeholder="max 450 caractères espace compris"
              maxlength="450"
            ></textarea>
          </label>

          <div class="infos mb-s">
            <div class="mb-s">
              <h5 class="mb-xxs">Téléphone</h5>
              <FormInput name="onsitePartnerPhone" />
            </div>

            <div class="mb-s">
              <h5 class="mb-xxs">E-mail</h5>
              <FormInput name="onsitePartnerEmail" />
            </div>
          </div>

          <div class="mb-s">
            <h5 class="mb-xxs">Site internet</h5>
            <FormInput name="onsitePartnerWebsite" />
          </div>
        </div>

        <!-- HORIZON -->
        <div class="horizon mb-l">
          <h4>Chaine Horizon</h4>
          <p class="description mb-s">
            Merci de renseigner vos coordonnées pour les Groupes souhaitant plus
            d'informations sur ce projet.
          </p>

          <div class="mb-s">
            <h5 class="mb-xxs">Unité *</h5>
            <SelectCustom
              name="horizonUnit"
              placeholder="Choisissez une unité"
              selectUnits
              :data="chromeData.lists.units"
            />
          </div>

          <div class="infos mb-s">
            <div class="mb-s">
              <h5 class="mb-xxs">Nom *</h5>
              <FormInput name="horizonLastname" :required="true" />
            </div>

            <div class="mb-s">
              <h5 class="mb-xxs">Prénom *</h5>
              <FormInput name="horizonFirstname" :required="true" />
            </div>
          </div>

          <div class="infos mb-s">
            <div class="mb-s">
              <h5 class="mb-xxs">Téléphone</h5>
              <FormInput name="horizonPhone" />
            </div>

            <div class="mb-s">
              <h5 class="mb-xxs">E-mail *</h5>
              <FormInput name="horizonEmail" :required="true" />
            </div>
          </div>
        </div>

        <!-- PHOTOS -->
        <div class="photos">
          <h4 class="mb-xs">Ajouter une photo</h4>

          <div class="mb-s">
            <h5 class="mb-xxs">Photo</h5>
            <Upload
              ref="uploadPhotos"
              name="photos[]"
              icon="image"
              :max="3"
              :options="{
                allowFileTypeValidation: true,
                acceptedFileTypes: ['image/*'],
                allowFileSizeValidation: true,
                maxFileSize: '5MB',
              }"
            />
          </div>
        </div>

        <div class="error m-m" v-if="showError?.message">
          <h4>{{ showError.message }}</h4>

          <ul>
            <li v-for="(val, key) in showError?.errors" :key="key">
              {{ val.join(',') }}
            </li>
          </ul>
        </div>

        <Btn class="send mt-s">Je publie</Btn>
      </form>
    </div>

    <Feedback v-model:visible="feedbackVisible" :badge="badgeFeedback">
      <template v-if="loading">
        <h4 class="mb-xxs">Document en cours d'envoi...</h4>
        <p>Ta fiche projet est en cours d'envoi...</p>
      </template>

      <template v-else>
        <h4 class="mb-xxs">Merci !</h4>
        <p>
          Ton projet a bien été envoyé. Après vérification, il sera publié et
          disponible pour tous les Animateurs et Animatrices.
        </p>
      </template>
    </Feedback>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive } from 'vue'
import { chrome } from '@/guides/endpoints/chrome'
import { create } from '@/guides/endpoints/projects'

import Alert from '@/components/Alert.vue'
import BackBtn from '@/components/BackBtn.vue'
import FormInput from '@/components/FormInput.vue'
import Btn from '@/components/Btn.vue'
import Feedback from '@/components/Feedback.vue'
import SelectCustom from '@/components/SelectCustom.vue'
import Upload from '@/components/Upload.vue'

export default defineComponent({
  components: {
    Alert,
    FormInput,
    Btn,
    BackBtn,
    Feedback,
    SelectCustom,
    Upload,
  },
  setup() {
    const chromeData = ref()
    const showAlert = ref(false)
    const uploadPhotos = ref()
    const typesList = ref()

    const projectTypes = ref<Array<string>>([''])
    const addTypes = () => {
      projectTypes.value.length += 1
    }
    const removeTypes = (index: any) => {
      const type = document.querySelector(`#scw-${index}`)

      type?.remove()
    }

    const tempHousing = [
      { name: 'Tentes', slug: 'Tentes' },
      { name: 'Bâtiments', slug: 'Bâtiments' },
      { name: 'Autres', slug: 'Autres' },
    ]

    chrome().then(r => {
      chromeData.value = r.data

      typesList.value = chromeData.value.lists.projectTypes.filter(function(
        type: any
      ) {
        return !type.slug.includes('projet-annee-')
      })
    })

    // Category
    const category = ref('projet-camp') //projet-camp vs projet-dannee

    const updateCategory = (value: any) => {
      category.value = value

      typesList.value = chromeData.value.lists.projectTypes.filter(function(
        type: any
      ) {
        if (value === 'projet-dannee') {
          return type.slug.includes('projet-annee-')
        } else {
          return !type.slug.includes('projet-annee-')
        }
      })
    }

    const formel = ref()
    const showError = ref({})
    const badgeFeedback = ref('loading')

    const form = reactive({})

    const feedbackVisible = ref(false)
    const loading = ref(false)

    const showFeedback = () => {
      feedbackVisible.value = true
    }

    const onError = (error: any) => {
      feedbackVisible.value = false
      showError.value = error

      showAlert.value = true

      window.setTimeout(() => {
        showAlert.value = false
      }, 5000)
    }

    const onSuccess = () => {
      badgeFeedback.value = 'success'
    }

    const onSubmit = () => {
      if (loading.value) {
        return
      }

      showError.value = {}
      loading.value = true
      feedbackVisible.value = true

      const data = new FormData(formel.value)

      data.append('category', category.value as string)

      const photos = uploadPhotos.value.getFiles()
      data.delete('photos[]')

      if (photos.length) {
        photos.forEach((el: any) => {
          data.append('photos[]', el.file)
        })
      }

      create(data)
        .then(d => {
          if (d.data?.status === 200) {
            onSuccess()
          } else {
            onError(d.data)
          }
        })
        .catch(() => {
          onError({
            message: !navigator.onLine
              ? 'Il semblerait que tu sois hors connexion. Connecte toi à internet pour envoyer ton formulaire.'
              : "Il semblerait qu'une erreur ce soit glissée quelque part.",
          })
        })
        .finally(() => {
          loading.value = false
        })
    }

    return {
      form,
      feedbackVisible,
      showFeedback,
      onSubmit,
      loading,
      formel,
      showError,
      badgeFeedback,
      showAlert,
      uploadPhotos,
      chromeData,
      projectTypes,
      addTypes,
      removeTypes,
      updateCategory,
      category,
      tempHousing,
      typesList,
    }
  },
})
</script>

<style lang="scss" scoped>
.project-form {
  @include form-base;

  background: $c-white;

  ::v-deep(.select-custom) {
    background-color: $athens-gray;
    border-color: $athens-gray;
  }
}

.select-custom-wrapper {
  display: flex;
  align-items: center;

  ::v-deep(.select-custom) {
    flex: 1;
  }

  .remove {
    padding: 1rem;
    width: 4rem;
    cursor: pointer;
  }

  &:first-of-type {
    padding-right: 4rem;

    .remove {
      display: none;
    }
  }
}

.category-selector {
  display: flex;
  align-content: center;
}

.category-selector__item {
  padding: 1.2rem 4rem;
  background-color: $athens-gray;
  cursor: pointer;

  &:hover,
  &.is-active {
    background-color: $tangerine;
    .h5 {
      color: white;
    }
  }
}

.description {
  font-size: 1.4rem;
  color: $hippie-blue;
}

@include mq(l) {
  .infos {
    display: flex;
    justify-content: space-between;

    > * {
      flex-basis: calc(50% - 1rem);
      margin-bottom: 0;
    }
  }
}
</style>
